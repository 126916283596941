import { startAppListening } from '../../app/listener';
import { logout } from './api';
import { deleteCookie } from '@vfde-sails/utils';
import { LOGIN_COOKIE } from './constants';

startAppListening({
    matcher: logout.matchFulfilled,
    effect: action => {
        deleteCookie(LOGIN_COOKIE);
        window.location.assign(action.meta.arg.originalArgs);
    },
});
