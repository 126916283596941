import { createAppSelector } from '../../app/selector';
import { selectQuery } from './slice';
import { search } from './api';

const createSearchSelector = createAppSelector(
    selectQuery,
    query => query && search.select(query),
);

const selectSearchResultQuery = createAppSelector(
    [
        state => state,
        createSearchSelector,
    ],
    (state, selector) => selector && selector(state),
);

const selectLoading = createAppSelector(
    [selectSearchResultQuery],
    query => {
        if (!query) {
            return false;
        }

        return query.isLoading;
    },
);

const selectSearchResults = createAppSelector(
    [selectSearchResultQuery],
    query => {
        if (!query) {
            return [];
        }

        return query.data?.sections || [];
    },
);

const selectResultCount = createAppSelector(
    [selectSearchResultQuery],
    query => {
        if (!query) {
            return 0;
        }

        return query.data?.resultCount || 0;
    },
);

const selectHasError = createAppSelector(
    [selectSearchResultQuery],
    query => {
        if (!query) {
            return false;
        }

        return query.isError;
    },
);

export {
    selectLoading,
    selectSearchResults,
    selectHasError,
    selectResultCount,
};
