// istanbul ignore file
import {
    NAV_CLASSNAME,
    NAV_ICON_NAV_ITEM_CLASSNAME,
} from '../GlobalNavigation/constants';

/**
 * Meta nav item class
 */
export const META_NAV_ITEM_CLASSNAME = `${NAV_CLASSNAME}__meta-nav-item`;
/**
 * Meta nav item class for contact number
 */
export const META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME = `${META_NAV_ITEM_CLASSNAME}--contact-number`;
/**
 * Meta nav item class for contact number visible
 */
export const META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME = `${META_NAV_ITEM_CLASSNAME}--contact-number-visible`;
/**
 * Meta nav item class for contact button
 */
export const META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME = `${NAV_ICON_NAV_ITEM_CLASSNAME}--contact`;
/**
 * Meta nav item class for contact number visible
 */
export const META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME = `${NAV_ICON_NAV_ITEM_CLASSNAME}--contact-visible`;
/**
 * Meta nav link class
 */
export const META_NAV_LINK_CLASSNAME = `${NAV_CLASSNAME}__meta-nav-link`;
