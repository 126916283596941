import { startAppListening } from '../../app/listener';
import { search } from './api';
import { NO_RESULT_ID } from './constants';
import {
    clearSearchTrackingProperties,
    saveSearchTrackingProperties,
} from './helpers/tracking';
import { resetSearch } from './slice';
import { isAnyOf } from '@reduxjs/toolkit';

startAppListening({
    matcher: search.matchFulfilled,
    effect: action => {
        if (action.payload.resultCount && action.payload.sections[0].id !== NO_RESULT_ID) {
            saveSearchTrackingProperties({
                searchType: 'autosuggest',
                searchTerms: action.meta.arg.originalArgs,
                searchNumberOfResults: action.payload.resultCount,
            });
        }
    },
});

startAppListening({
    matcher: isAnyOf(search.matchRejected, resetSearch),
    effect: () => {
        clearSearchTrackingProperties();
    },
});
