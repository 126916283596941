var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (numberToDisplay) {
      var pug_indent = [];
pug_html = pug_html + "\n\u003Cbutton class=\"mc-nav__meta-nav-link\" type=\"button\"\u003E\u003Cspan class=\"mc-nav__meta-nav-link-contact-label\"\u003EBeratung &amp; Bestellung: " + (pug.escape(null == (pug_interp = numberToDisplay) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n  \u003Csvg class=\"mc-nav__icon-contact\" aria-hidden=\"true\"\u003E\n    \u003Cuse xlink:href=\"\u002Fsimplicity\u002Ficons\u002Finfo-circle.svg#icon\"\u003E\u003C\u002Fuse\u003E\n  \u003C\u002Fsvg\u003E\n\u003C\u002Fbutton\u003E";
    }.call(this, "numberToDisplay" in locals_for_with ?
        locals_for_with.numberToDisplay :
        typeof numberToDisplay !== 'undefined' ? numberToDisplay : undefined));
    ;;return pug_html;}

module.exports = template