import { IInitialState } from './interface';
import { getCookie } from '@vfde-sails/utils';
import {
    BASKET_COUNT_DEFAULT_COOKIE,
    BASKET_COUNT_OLS_COOKIE,
} from './constants';
import createAppSlice from '../../app/createAppSlice';

const initialState: IInitialState = {
    basketCount: 0,
};

const basketSlice = createAppSlice({
    name: 'Basket',
    initialState,
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            rejectValue: string;
        }>();

        return {
            getBasketCount: createAThunk<number, void>(
                () => {
                    const isValidBasketCount = (value: any): value is number => typeof value === 'number' && !Number.isNaN(value);

                    const olsCookieValue = getCookie(BASKET_COUNT_OLS_COOKIE);
                    const defaultCookieValue = getCookie(BASKET_COUNT_DEFAULT_COOKIE);

                    let parsedCookieValue = olsCookieValue ? parseInt(olsCookieValue, 10) : olsCookieValue;

                    if (!isValidBasketCount(parsedCookieValue)) {
                        parsedCookieValue = defaultCookieValue ? parseInt(defaultCookieValue, 10) : defaultCookieValue;

                        if (!isValidBasketCount(parsedCookieValue)) {
                            parsedCookieValue = 0;
                        }
                    }

                    return Math.max(0, parsedCookieValue);
                },
                {
                    fulfilled: (state, action) => {
                        state.basketCount = action.payload;
                    },
                },
            ),
            clearBasket: create.reducer(state => {
                state.basketCount = 0;
            }),
        };
    },
    selectors: {
        selectBasketCount: (sliceState): IInitialState['basketCount'] => sliceState.basketCount,
    },
});

export default basketSlice;

export const {
    getBasketCount,
    clearBasket,
} = basketSlice.actions;

export const {
    selectBasketCount,
} = basketSlice.selectors;
