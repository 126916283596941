import {
    createSearchInputComponent,
    ISearchInputBusinessLogic,
    ISearchInputProperties,
} from '@vfde-brix/search-input';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';

const mountSearchInput = (
    selector: string,
    properties: ISearchInputProperties | ISearchInputBusinessLogic = NO_PATTERN_BUSINESS_LOGIC,
) => createSearchInputComponent(document.querySelector(selector)!, properties);

export default mountSearchInput;
