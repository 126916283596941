import { SkipToLinkType } from '../interface';
import {
    NAV_ROOT_LEVEL_ID,
    NAV_SKIP_TO_CONTENT_CLASSNAME,
} from '../constants';
import { useDispatch } from '../../../app/store';
import { triggerSkipToLink } from '../slice';

/**
 * Get skip link type from anchor
 */
export const getSkipLinkType = (anchor: string): SkipToLinkType | undefined => {
    switch (anchor) {
        case 'content':
            return SkipToLinkType.Content;
        case NAV_ROOT_LEVEL_ID:
            return SkipToLinkType.Navigation;
    }
};

/**
 * allows tab tabnavigation in desktop viewport
 * TODO: move this to component MDD?
 * @param glHtml - the gloabl navigation markup
 */
export const skipToService = (glHtml: HTMLElement) => {
    const dispatch = useDispatch();

    const skipToContainer = glHtml.getElementsByClassName(NAV_SKIP_TO_CONTENT_CLASSNAME)[0];

    skipToContainer.addEventListener('click', (e: Event) => {
        const link: HTMLAnchorElement = (<HTMLElement>e.target).closest('a')!;

        if (link) {
            e.preventDefault();
            const { href, hash } = link;
            const linkType = getSkipLinkType(hash.replace('#', ''))!;
            dispatch(triggerSkipToLink({ linkType, href }));
        }
    });
};
