import { setFocusTrap } from '@vfde-brix/core';
import {
    createGlobalSearchOverlayComponent,
    IGlobalSearchOverlayBusinessLogic,
} from '@vfde-brix/global-search-overlay';
import { SearchInput } from '@vfde-brix/search-input';
import { useDispatch } from '../../../app/store';
import {
    resetSearch,
    setOverlayOpen,
} from '../slice';

/**
 * Global Search Overlay
 */
export const mountGlobalSearchOverlay = (
    container: HTMLElement,
    inputField: SearchInput,
    callbacks?: { onOpen?: CallableFunction; onClose?: CallableFunction },
) => {
    const dispatch = useDispatch();

    let removeFocusTrap: CallableFunction | undefined | null;

    const tryRemoveFocusTrap = () => {
        if (!removeFocusTrap) {
            return;
        }

        removeFocusTrap();
        removeFocusTrap = null;
    };

    const props: IGlobalSearchOverlayBusinessLogic = {
        onOpen: () => {
            removeFocusTrap = setFocusTrap(container);
            inputField.getInputField().focus();
            callbacks?.onOpen?.();
        },
        onClose: () => {
            // update state on backdrop click
            tryRemoveFocusTrap();
            dispatch(setOverlayOpen(false));
            dispatch(resetSearch());
            callbacks?.onClose?.();
        },
        closeButton: {
            onClick: () => {
                tryRemoveFocusTrap();
                dispatch(setOverlayOpen(false));
            },
        },
    };

    return createGlobalSearchOverlayComponent(container, props);
};
