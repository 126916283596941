import { isDesktopViewport } from '@vfde-brix/core';
import {
    NAV_BURGER_CLASSNAME,
    NAV_ICON_NAV_LINK_CLASSNAME,
    NAV_LOGO_CLASSNAME,
    NAV_META_NAV_LINK_CLASSNAME,
    NAV_SKIP_TO_CONTENT_CLASSNAME,
} from '../constants';
import {
    DROPDOWN_OPENER_CLASSNAME,
    LOGIN_DROPDOWN_ID,
} from '../../MyVodafoneSection/constants';

/**
 * Changes tabindex according to the viewport
 * In mobile:
 *   1. logo
 *   2. icon-nav-links
 *   3. links within the login-dropdown
 *   4. burger
 * In desktop:
 *   1. meta-nav-links
 */
export const setTabIndex = (globalNavigation: HTMLElement) => {
    let indexMobileViewport: Element[];
    let indexDesktopViewport: Element[];

    const getElements = () => {
        indexMobileViewport = [
            ...globalNavigation.getElementsByClassName(NAV_LOGO_CLASSNAME),
            ...Array.from(globalNavigation.getElementsByClassName(NAV_ICON_NAV_LINK_CLASSNAME)).flatMap(element => {
                if (element.classList.contains(DROPDOWN_OPENER_CLASSNAME)) {
                    // current element is the  MeinVodafone login link, so we return an array containing this link
                    // plus the links within the MeinVodafone dropdown so they appear in correct tabindex order
                    return [element, ...globalNavigation.querySelectorAll(`#${LOGIN_DROPDOWN_ID} a`)];
                }

                return element;
            }),
            ...globalNavigation.getElementsByClassName(NAV_BURGER_CLASSNAME),
        ];

        indexDesktopViewport = [
            ...globalNavigation.getElementsByClassName(NAV_META_NAV_LINK_CLASSNAME),
        ];
    };

    getElements();

    /**
     * Set the tab index for the current viewport and remove for the other
     */
    const applyTabIndex = (setIndexForViewport: Element[], resetIndexForViewport: Element[]) => {
        const skipLinks = globalNavigation.getElementsByClassName(NAV_SKIP_TO_CONTENT_CLASSNAME)[0].getElementsByTagName('a');

        [...skipLinks, ...setIndexForViewport].forEach((element, index) => {
            element.setAttribute('tabindex', `${index + 1}`);
        });

        resetIndexForViewport.forEach(element => element.setAttribute('tabindex', '0'));
    };

    // listen to viewport changes
    const handleViewPortOrDomChange = () => {
        if (isDesktopViewport()) {
            applyTabIndex(indexDesktopViewport, indexMobileViewport);
        }
        else {
            applyTabIndex(indexMobileViewport, indexDesktopViewport);
        }
    };

    const observer = new MutationObserver(() => {
        getElements(); // the DOM changed, so we need to get the elements again
        handleViewPortOrDomChange();
    });

    // observe changes to the DOM (required to make contact section tab-able since its initialization is deferred)
    observer.observe(globalNavigation, {
        subtree: true,
        childList: true,
    });

    handleViewPortOrDomChange();

    return handleViewPortOrDomChange;
};
