import { NAV_ITEM_SELECTED_CLASSNAME } from './constants';
import './style.scss';
import { mountBreadcrumb } from './components/Breadcrumb';
import { mountFooter } from './components/Footer';
import {
    mountMdd,
    toggleMdd,
    toggleMddNavItem,
} from './components/Mdd';
import { mountSubnav } from './components/Subnav';
import { getDesktopMediaQueryList } from '@vfde-brix/core';
import { startAppListening } from '../../app/listener';
import { selectIsMddOpen } from './slice';

function mountGlobalNavigationFeature () {
    // ResizeHandlers are triggered on every pixel of resize
    const resizeHandlers: CallableFunction[] = [];

    // breakpointHandlers are triggered when the viewport switches from mobile to desktop or vice versa
    const breakpointHandlers: CallableFunction[] = [];

    breakpointHandlers.push(...mountMdd());
    breakpointHandlers.push(...mountSubnav());
    breakpointHandlers.push(...mountFooter());

    resizeHandlers.push(...mountBreadcrumb()); // breadcrumb needs to track resize to adjust its scroll position

    const desktopBreakpoint = getDesktopMediaQueryList();
    desktopBreakpoint.addEventListener('change', () => {
        breakpointHandlers.forEach(handlerFn => {
            handlerFn();
        });
    });

    // Prevent triggering while still resizing
    let timeoutId: NodeJS.Timeout | null = null;

    window.addEventListener('resize', () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            timeoutId = null;

            resizeHandlers.forEach(handlerFn => {
                handlerFn();
            });
        }, 200);
    });

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectIsMddOpen(currentState) !== selectIsMddOpen(previousState),
        effect: (_action, { getState }) => {
            const isMddOpen = selectIsMddOpen(getState());

            toggleMdd(false, isMddOpen);

            if (isMddOpen) {
                const activeItems = document.getElementsByClassName(NAV_ITEM_SELECTED_CLASSNAME) as HTMLCollectionOf<HTMLLIElement>;
                toggleMddNavItem(activeItems);
            }
        },
    });
}

export default mountGlobalNavigationFeature;
