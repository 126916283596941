import {
    SUBNAV_ITEM_LEVEL_1_CLASSNAME,
    SUBNAV_TOGGLE_BUTTON_CLASSNAME,
    SUBNAV_CLASSNAME,
    SUBNAV_LIST_CONTAINER_CLASSNAME,
} from '../constants';
import initNavigation, {
    handleSubmenuMaxHeightTransitionEnd,
    initTrigger,
    isSubmenuOpen,
    resetAllSubmenus,
    toggleSubmenu,
} from '../helpers/navigationHelper';
import { getChildrenByTagName } from '../../../helpers/domHelper';

/**
 * Init Sublevel navigation
 */
export const mountSubnav = (): CallableFunction[] => {
    const subNavElement = document.getElementsByClassName(SUBNAV_CLASSNAME)[0] as HTMLElement;
    const resizeHandlers: CallableFunction[] = [];

    if (!subNavElement) {
        return resizeHandlers;
    }

    const resizeHandler = initToggleButton(subNavElement);

    // toggle button
    resizeHandler && resizeHandlers.push(resizeHandler);

    // Initialize subnav navigation functionality
    const listItems = Array.from(subNavElement.getElementsByClassName(SUBNAV_ITEM_LEVEL_1_CLASSNAME) as HTMLCollectionOf<HTMLLIElement>);

    handleOutsideClick(subNavElement, () => {
        resetAllSubmenus(listItems);
    });

    resizeHandlers.push(initNavigation(listItems, true));

    return resizeHandlers;
};

const initToggleButton = (subNavElement: HTMLElement): CallableFunction | null => {
    const toggleButton = subNavElement.getElementsByClassName(SUBNAV_TOGGLE_BUTTON_CLASSNAME)[0] as HTMLButtonElement;

    if (!toggleButton) {
        return null;
    }

    const listContainer = subNavElement.getElementsByClassName(SUBNAV_LIST_CONTAINER_CLASSNAME)[0] as HTMLElement;

    initTrigger(toggleButton, getChildrenByTagName<HTMLElement>(toggleButton.nextElementSibling as HTMLElement, 'ul')[0]);

    toggleButton.addEventListener('click', () => {
        const isOpen = () => isSubmenuOpen(toggleButton);
        handleSubmenuMaxHeightTransitionEnd('--mc-submenu-height', listContainer, isOpen);

        // the timeout is needed to trigger the transition correctly when closing the submenu
        setTimeout(() => {
            toggleSubmenu(toggleButton, !isOpen());
        }, 50);
    });

    return () => {
        toggleSubmenu(toggleButton, false);
    };
};

/**
 * Close the subnav items on outside click/touch
 */
const handleOutsideClick = (subNavContainer: HTMLElement, callback: CallableFunction) => {
    ['click', 'touchstart'].forEach(eventName => {
        window.addEventListener(eventName, e => {
            const target = e.target as HTMLElement;

            if (subNavContainer.contains(target)) {
                return;
            }

            callback();
        }, true);
    });
};
