import { initFootnotes } from './helpers/initFootnotes';
import { initJumpLinkScrolling } from './helpers/initJumpLinkScrolling';
import './style.scss';

const mountGlobalSearchContainer = () => {
    initJumpLinkScrolling();
    initFootnotes();
};

export default mountGlobalSearchContainer;
