import {
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { BSHOP_LOGOUT_URL } from './constants';

const meinVodafoneApi = createApi({
    reducerPath: 'MeinVodafoneService',
    baseQuery: fetchBaseQuery({
        baseUrl: BSHOP_LOGOUT_URL,
    }),
    endpoints: build => ({
        logout: build.mutation<void, string>({
            query: () => ({
                url: '',
                method: 'POST',
                body: JSON.stringify({}),
            }),
        }),
    }),
});

export default meinVodafoneApi;

export const {
    logout,
} = meinVodafoneApi.endpoints;
