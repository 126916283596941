var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (lastLoginDatetime, lastLoginDay, lastLoginTime, textResources) {
      var pug_indent = [];
pug_html = pug_html + "\n\u003Cdiv class=\"mc-nav-login-dropdown__inner\"\u003E";
if (lastLoginDatetime) {
pug_html = pug_html + "\n  \u003Cdiv class=\"mc-nav-login-dropdown__text-container\"\u003E\n    \u003Cp\u003E\u003Cspan class=\"mc-nav-login-dropdown__last-login-text\"\u003E" + (pug.escape(null == (pug_interp = textResources.lastLogin) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\n      \u003Ctime" + (" class=\"mc-nav-login-dropdown__last-login-value\""+pug.attr("datetime", lastLoginDatetime, true, true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = lastLoginDay) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = textResources.at) ? "" : pug_interp)) + " " + (pug.escape(null == (pug_interp = lastLoginTime) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftime\u003E\n    \u003C\u002Fp\u003E\n  \u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\n  \u003Cdiv class=\"mc-nav-login-dropdown__button-container\"\u003E\n    \u003Cdiv class=\"mc-nav-login-dropdown__open-my-vf\"\u003E\u003Ca" + (" class=\"brix-button brix-button--strong brix-button--rectangle brix-button--large brix-button--fullwidth\""+pug.attr("href", textResources.buttonHrefOpenMyVf, true, true)) + "\u003E\u003Cspan class=\"brix-button__text\"\u003E" + (pug.escape(null == (pug_interp = textResources.buttonTextOpenMyVf) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n    \u003Cdiv class=\"mc-nav-login-dropdown__logout\"\u003E\u003Ca" + (" class=\"brix-button brix-button--light brix-button--rectangle brix-button--large brix-button--fullwidth brix-spacing-0\""+" id=\"mc-nav-logout-button\""+pug.attr("href", textResources.buttonHrefLogout, true, true)) + "\u003E\u003Cspan class=\"brix-button__text\"\u003E" + (pug.escape(null == (pug_interp = textResources.buttonTextLogout) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\n  \u003C\u002Fdiv\u003E\n\u003C\u002Fdiv\u003E";
    }.call(this, "lastLoginDatetime" in locals_for_with ?
        locals_for_with.lastLoginDatetime :
        typeof lastLoginDatetime !== 'undefined' ? lastLoginDatetime : undefined, "lastLoginDay" in locals_for_with ?
        locals_for_with.lastLoginDay :
        typeof lastLoginDay !== 'undefined' ? lastLoginDay : undefined, "lastLoginTime" in locals_for_with ?
        locals_for_with.lastLoginTime :
        typeof lastLoginTime !== 'undefined' ? lastLoginTime : undefined, "textResources" in locals_for_with ?
        locals_for_with.textResources :
        typeof textResources !== 'undefined' ? textResources : undefined));
    ;;return pug_html;}

module.exports = template