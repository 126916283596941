import { trackIt } from '@vfde-sails/tracking';
import { TRACKING_SEARCH } from '../constants';
import { ISearchTrackingProperties } from '../interface';

/**
 * Track reveal search-overlay
 */
export const trackRevealSearchOverlay = () => {
    const trackingData = {
        /* eslint-disable-next-line camelcase, @typescript-eslint/naming-convention */
        search_action: 'reveal',
        /* eslint-enable */
    };

    trackIt(TRACKING_SEARCH, trackingData);
};

/**
 * Track trigger autosuggest
 */
export const trackTriggerAutosuggest = () => {
    const trackingData = {
        /* eslint-disable camelcase, @typescript-eslint/naming-convention */
        search_action: 'trigger',
        search_type: 'autosuggest',
        /* eslint-enable */
    };

    trackIt(TRACKING_SEARCH, trackingData);
};

/**
 * Save the given search properties in the sessionStorage
 * so the next page can track an according pageview
 */
export const saveSearchTrackingProperties = (props: ISearchTrackingProperties) => {
    Object.keys(props).forEach(prop => {
        sessionStorage.setItem(prop, `${props[prop as keyof ISearchTrackingProperties]}`);
    });
};

/**
 * Deletes all saved search properties
 * from sessionStorage
 */
export const clearSearchTrackingProperties = () => {
    ['searchType', 'searchTerms', 'searchNumberOfResults'].forEach(prop => {
        sessionStorage.removeItem(prop);
    });
};
