import createAppSlice from '../../app/createAppSlice';
import {
    IInitialState,
    ILoginInfo,
} from './interface';
import { original } from '@reduxjs/toolkit';
import { getCookie } from '@vfde-sails/utils';
import { LOGIN_COOKIE } from './constants';

const initialState: IInitialState = {
    hasError: false,
    loginInfo: null,
    isOpen: false,
};

const meinVodafoneSlice = createAppSlice({
    name: 'MeinVodafone',
    initialState,
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            rejectValue: string;
        }>();

        return {
            getLoginInfo: createAThunk<ILoginInfo | null, void, { rejectValue: string }>((_, thunkAPI) => {
                const loginCookieValue = getCookie(LOGIN_COOKIE);

                if (!loginCookieValue) {
                    return null;
                }

                try {
                    const lastLoginTimestamp = JSON.parse(decodeURIComponent(loginCookieValue)).lastLoginDate;
                    const loginInfoFromCookie: ILoginInfo = { lastLoginTimestamp };

                    return loginInfoFromCookie;
                }
                catch (err) {
                    throw thunkAPI.rejectWithValue('Error while parsing login cookie');
                }
            }, {
                fulfilled: (state, action) => {
                    state.loginInfo = action.payload;
                },
            }),
            toggleDropdownState: create.reducer<boolean | undefined>((state, action) => {
                state.isOpen = action.payload ?? !original(state)!.isOpen;
            }),
        };
    },
    selectors: {
        selectIsOpen: sliceState => sliceState.isOpen,
        selectLoginInfo: sliceState => sliceState.loginInfo,
        selectIsLoggedIn: sliceState => !!sliceState.loginInfo,
    },
});

export default meinVodafoneSlice;

export const {
    getLoginInfo,
    toggleDropdownState,
} = meinVodafoneSlice.actions;

export const {
    selectIsOpen,
    selectLoginInfo,
    selectIsLoggedIn,
} = meinVodafoneSlice.selectors;
