import createAppSlice from '../../app/createAppSlice';
import {
    IInitialState,
    SkipToLinkType,
} from './interface';
import {
    focusFirstElement,
    isNoMddMode,
} from '../../helpers/domHelper';
import { isDesktopViewport } from '@vfde-brix/core';
import {
    NAV_LOGO_ID,
    NAV_ROOT_LEVEL_ID,
} from './constants';
import { toggleMdd } from './components/Mdd';

const initialState: IInitialState = {
    isMddOpen: false,
};

const navigationSlice = createAppSlice({
    name: 'Navigation',
    initialState,
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            rejectValue: string;
        }>();

        return {
            setMddState: create.reducer<boolean>((state, action) => {
                state.isMddOpen = action.payload;
            }),
            triggerSkipToLink: createAThunk<void, { linkType: SkipToLinkType; href: string; }>(
                (payload, thunkAPI) => {
                    window.location.assign(payload.href);

                    switch (payload.linkType) {
                        case SkipToLinkType.Navigation: {
                            const isInNoMddMode = isNoMddMode();

                            if (isInNoMddMode) {
                                // if noMdd mode is active, focus the logo link (in all viewports)
                                focusFirstElement(`#${NAV_LOGO_ID}`, null);
                            }
                            else if (isDesktopViewport()) {
                                // if noMdd is not active and viewport is desktop, focus the nav root
                                focusFirstElement(`#${NAV_ROOT_LEVEL_ID}`);
                            }
                            else {
                                // if noMdd is not active and viewport is mobile, open the navi and focus the first link
                                thunkAPI.dispatch(navigationSlice.actions.setMddState(true));
                                toggleMdd(true, true);
                            }

                            break;
                        }
                    }
                },
            ),
        };
    },
    selectors: {
        selectIsMddOpen: sliceState => sliceState.isMddOpen,
    },
});

export default navigationSlice;

export const {
    setMddState,
    triggerSkipToLink,
} = navigationSlice.actions;

export const {
    selectIsMddOpen,
} = navigationSlice.selectors;
