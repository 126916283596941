import {
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import {
    SEARCH_API_ENDPOINT_SEARCH,
    SEARCH_API_URL,
} from './constants';
import {
    ISearchAPIResultItem,
    ISearchResultSectionWrapper,
} from './interface';
import { groupSearchResult } from './helpers/groupSearchResult';

const searchApi = createApi({
    reducerPath: 'SearchService',
    baseQuery: fetchBaseQuery({
        baseUrl: SEARCH_API_URL,
    }),
    endpoints: build => ({
        search: build.query<ISearchResultSectionWrapper, string>({
            query: arg => `${SEARCH_API_ENDPOINT_SEARCH}${arg}`,
            transformResponse: (response: ISearchAPIResultItem[], _, arg) => {
                const searchResult = groupSearchResult(arg, response);

                return searchResult;
            },
        }),
    }),
});

export default searchApi;

export const {
    search,
} = searchApi.endpoints;
