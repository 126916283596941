import './style.scss';
import {
    LOGIN_DROPDOWN_ID,
    NAV_ITEM_MYVF_CLASSNAME,
} from './constants';
import mountLoginDropdown from './components/LoginDropdown';
import mountLoginTrigger, {
    initLoginTriggerEvents,
    LoginTriggerElements,
} from './components/LoginTrigger';
import { useDispatch } from '../../app/store';
import './listener';
import {
    getLoginInfo,
    selectIsLoggedIn,
} from './slice';
import { startAppListening } from '../../app/listener';

function mountMeinVodafoneFeature () {
    const dispatch = useDispatch();

    const loginDropdown = mountLoginDropdown(LOGIN_DROPDOWN_ID);
    const loginTriggerElements = mountLoginTrigger(NAV_ITEM_MYVF_CLASSNAME) || ({} as LoginTriggerElements);

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectIsLoggedIn(currentState) !== selectIsLoggedIn(previousState),
        effect: (_action, { getState }) => {
            const isLoggedIn = selectIsLoggedIn(getState());

            if (isLoggedIn) {
                initLoginTriggerEvents(loginTriggerElements, loginDropdown!);
            }
        },
    });

    dispatch(getLoginInfo());
}

export default mountMeinVodafoneFeature;
