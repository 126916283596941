import createAppSlice from '../../app/createAppSlice';
import { IInitialState } from './interface';
import { isAnyOf } from '@reduxjs/toolkit';
import { search } from './api';

const initialState: IInitialState = {
    query: null,
    isOverlayOpen: false,
};

const searchSlice = createAppSlice({
    name: 'Search',
    initialState,
    reducers: create => ({
        setOverlayOpen: create.reducer<boolean>((state, action) => {
            state.isOverlayOpen = action.payload;
        }),
        resetSearch: create.reducer(state => {
            state.query = null;
        }),
    }),
    selectors: {
        selectIsOverlayOpen: sliceState => sliceState.isOverlayOpen,
        selectQuery: sliceState => sliceState.query,
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                isAnyOf(search.matchFulfilled, search.matchPending, search.matchRejected),
                (state, action) => {
                    state.query = action.meta.arg.originalArgs;
                },
            )
        ;
    },
});

export default searchSlice;

export const {
    setOverlayOpen,
    resetSearch,
} = searchSlice.actions;

export const {
    selectIsOverlayOpen,
    selectQuery,
} = searchSlice.selectors;
