import { CLASSNAME_NO_SCROLL } from '@vfde-brix/core';

/**
 * This helper functions could be moved to Brix Core in future
 * because it is a general functionality.
 * But for now it is kept in here because it conflicts with
 * the old simplicity navigation and we don't want to possibly produce bugs
 * by providing a functionality which is not compatible with all pages
 * and not everybody is aware of the problem.
 */

/**
 * Disables the page scrolling and keeps the current scroll position
 */
export const disablePageScroll = () => {
    if (document.body.classList.contains(CLASSNAME_NO_SCROLL)) {
        return;
    }

    document.body.classList.add(CLASSNAME_NO_SCROLL);
};

/**
 * Enables the page scrolling again
 */
export const enablePageScroll = () => {
    if (!document.body.classList.contains(CLASSNAME_NO_SCROLL)) {
        return;
    }

    document.body.classList.remove(CLASSNAME_NO_SCROLL);
};
