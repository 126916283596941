import {
    createNotificationComponent,
    INotificationProperties,
    Notification,
} from '@vfde-brix/notification';

const mountNotification = (id: string, properties: INotificationProperties): Notification => {
    const container = document.getElementById(id)!;

    return createNotificationComponent(container, properties);
};

export default mountNotification;
