import './style.scss';
import { NAV_ITEM_BASKET_CLASSNAME } from './constants';
import mountBasketLink from './components/BasketLink';
import { useDispatch } from '../../app/store';
import {
    clearBasket,
    getBasketCount,
} from './slice';

function mountBasketFeature () {
    const dispatch = useDispatch();

    mountBasketLink(NAV_ITEM_BASKET_CLASSNAME);

    dispatch(getBasketCount());

    window.addEventListener('clearBasket', () => {
        // this event is dispatched on the basket page when you click the delete button (sails-checkout-app)
        dispatch(clearBasket());
    });
}

export default mountBasketFeature;
