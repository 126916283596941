import { META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME } from '../constants';
import { isKeyboardTriggeredClickEvent } from '@vfde-sails/utils';
import { closestUntil } from '../../../helpers/domHelper';
import { DOMEvent } from '../../../interface';
import { startAppListening } from '../../../app/listener';
import {
    selectContactNumber,
    toggleFlyout,
} from '../slice';
import { useDispatch } from '../../../app/store';

/**
 * Mount contact button
 */
const mountContactButton = (className: string): HTMLElement | undefined => {
    const containerElement = document.getElementsByClassName(className)[0] as HTMLElement;

    if (!containerElement) {
        return;
    }

    const dispatch = useDispatch();

    const contactClickHandler = (event: DOMEvent<MouseEvent, HTMLElement>): void => {
        event.preventDefault();
        const button = closestUntil(event.target, 'button', event.currentTarget);

        if (!button) {
            // ignore clicks inside the <li>
            // but outside the <button>
            return;
        }

        dispatch(toggleFlyout(isKeyboardTriggeredClickEvent(event)));
    };

    containerElement.addEventListener('click', contactClickHandler);

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectContactNumber(currentState) !== selectContactNumber(previousState),
        effect: (_action, { getState }) => {
            const contactNumber = selectContactNumber(getState());

            containerElement.classList.toggle(META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME, !!contactNumber);
        },
    });

    return containerElement;
};

export default mountContactButton;
