import { configureStore } from '@reduxjs/toolkit';
import basketSlice from '../container/Basket/slice';
import contactSlice from '../container/ContactSection/slice';
import navigationSlice from '../container/GlobalNavigation/slice';
import searchSlice from '../container/GlobalSearch/slice';
import searchApi from '../container/GlobalSearch/api';
import { listener } from './listener';
import meinVodafoneSlice from '../container/MyVodafoneSection/slice';
import meinVodafoneApi from '../container/MyVodafoneSection/api';

export const store = configureStore({
    reducer: {
        [basketSlice.reducerPath]: basketSlice.reducer,
        [contactSlice.reducerPath]: contactSlice.reducer,
        [navigationSlice.reducerPath]: navigationSlice.reducer,
        [searchSlice.reducerPath]: searchSlice.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [meinVodafoneSlice.reducerPath]: meinVodafoneSlice.reducer,
        [meinVodafoneApi.reducerPath]: meinVodafoneApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .prepend(listener.middleware) // Listener goes first because of special actions in it
        .concat(searchApi.middleware, meinVodafoneApi.middleware),
});

/**
 * Infer the `RootState` and `AppDispatch` types from the store itself
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Infer AppDispatch
 */
export type AppDispatch = typeof store.dispatch;

/**
 * Export dispatch function
 */
export const useDispatch = () => store.dispatch;
