/**
 * Global app namespace
 */
export const APP = 'NAVIGATION_MODULES';
/**
 * Global class prefix
 */
export const GLOBAL_CLASSNAME_PREFIX = 'mc';
/**
 * Timeout duration (in ms) for hovering elements
 * before the actual action is triggered
 */
export const HOVER_DELAY = 200;
/**
 * Classname for the noMDD mode (header visible but navigation invisible)
 */
export const CLASSNAME_NO_MDD_MODE = 'noMDD';
/**
 * Wrapper classname
 */
export const CLASSNAME_WRAPPER = 'wrapper';
