import {
    CLASSNAME_NO_MDD_MODE,
    CLASSNAME_WRAPPER,
} from '../constants';

/**
 * Get next sibling to an element by classname
 */
export function getNextSiblingByClass<T extends HTMLElement = HTMLElement> (element: HTMLElement, className: string): T {
    let next: HTMLElement = element;

    do {
        next = next.nextElementSibling as HTMLElement;
    } while (next && !next.classList.contains(className));

    return next as T;
}

/**
 * Get previous sibling to an element by classname
 */
export function getPreviousSiblingByClass<T extends HTMLElement = HTMLElement> (element: HTMLElement, className: string): T {
    let prev: HTMLElement = element;

    do {
        prev = prev.previousElementSibling as HTMLElement;
    } while (prev && !prev.classList.contains(className));

    return prev as T;
}

/**
 * Get next sibling to an element by tag name
 */
export function getNextSiblingByTagName<T extends HTMLElement = HTMLElement> (element: HTMLElement, tagName: string): T {
    let next: HTMLElement = element;

    do {
        next = next.nextElementSibling as HTMLElement;
    } while (next && next.nodeName.toLowerCase() !== tagName);

    return next as T;
}

/**
 * Get previous sibling to an element by tag name
 */
export function getPreviousSiblingByTagName<T extends HTMLElement = HTMLElement> (element: HTMLElement, tagName: string): T {
    let prev: HTMLElement = element;

    do {
        prev = prev.previousElementSibling as HTMLElement;
    } while (prev && prev.nodeName.toLowerCase() !== tagName);

    return prev as T;
}

/**
 * Returns the closest parent element starting from the given element
 * which matches the given selector. An optional context can be provided
 * to limit the DOM traversal.
 * @param element The element to start the search from
 * @param selector The selector to match the closest element with
 * @param context Optional context element
 * @returns Closest element within the given context or null
 */
export const closestUntil = (element: HTMLElement | null | undefined, selector: string, context?: HTMLElement): HTMLElement | null => {
    let currentElement: HTMLElement | null | undefined = element;

    while (currentElement) {
        if (context && !context.contains(currentElement)) {
            return null;
        }

        if (currentElement.matches(selector)) {
            return currentElement;
        }

        currentElement = currentElement.parentElement || null;
    }

    return null;
};

/**
 * Focus first element in container
 * @param selector The CSS selector to select the element
 * @param childrenSelector Optional selector to select a child element within the selector
 */
export const focusFirstElement = (selector: string, childrenSelector: string | null = 'a'): void => {
    const element = document.querySelector<HTMLAnchorElement>(`${selector}${childrenSelector ? ` ${childrenSelector}` : ''}`);
    element && element.focus();
};

/**
 * Get direct descendants by tag name
 */
export function getChildrenByTagName<E extends Element = Element> (parent: HTMLElement, tagName: string): E[] {
    return Array.from(parent.children).filter(element => element.nodeName.toLowerCase() === tagName) as E[];
}

/**
 * Get direct descendants by class name
 */
export function getChildrenByClassname<E extends Element = Element> (parent: HTMLElement, className: string): E[] {
    return Array.from(parent.children).filter(element => element.classList.contains(className)) as E[];
}

/**
 * Checks if the page is in 'noMDD' mode
 * (hidden mega drop down)
 */
export const isNoMddMode = () => document.getElementsByClassName(CLASSNAME_WRAPPER)[0]?.classList.contains(CLASSNAME_NO_MDD_MODE) ?? false;
