import {
    DROPDOWN_OPENER_CLASSNAME,
    DROPDOWN_OPENER_INDICATOR_CLASSNAME,
    DROPDOWN_OPENER_LOGGED_IN_CLASSNAME,
    DROPDOWN_OPENER_OPEN_CLASSNAME,
    LOGIN_DROPDOWN_ID,
} from '../constants';
import {
    compareFocusPosition,
    isKeyboardTriggeredClickEvent,
} from '@vfde-sails/utils';
import {
    isDesktopViewport,
    isTouchInput,
} from '@vfde-brix/core';
import { HOVER_DELAY } from '../../../constants';
import { startAppListening } from '../../../app/listener';
import {
    selectIsLoggedIn,
    selectIsOpen,
    toggleDropdownState,
} from '../slice';
import { useDispatch } from '../../../app/store';

/**
 * Login Trigger Elements
 */
export interface LoginTriggerElements {
    /**
     * Nav item
     */
    navItem: HTMLElement;
    /**
     * Nav link
     */
    navLink: HTMLAnchorElement;
    /**
     * Indicator
     */
    indicator: HTMLElement;
}

/**
 * Mount trigger element and dispatch actions on events
 */
const mountLoginTrigger = (className: string): LoginTriggerElements | null => {
    const navItem = document.getElementsByClassName(className)[0] as HTMLLIElement;

    if (!navItem) {
        return null;
    }

    const navLink = navItem.getElementsByClassName(DROPDOWN_OPENER_CLASSNAME)[0] as HTMLAnchorElement;
    const indicator = navLink.getElementsByClassName(DROPDOWN_OPENER_INDICATOR_CLASSNAME)[0] as HTMLElement;

    navLink.setAttribute('aria-controls', LOGIN_DROPDOWN_ID);
    navLink.setAttribute('aria-expanded', 'false');

    const loginTriggerElements = {
        navItem,
        navLink,
        indicator,
    };

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectIsLoggedIn(currentState) !== selectIsLoggedIn(previousState),
        effect: (_action, { getState }) => {
            const isLoggedIn = selectIsLoggedIn(getState());

            updateLoginTrigger(loginTriggerElements, isLoggedIn);
        },
    });

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectIsOpen(currentState) !== selectIsOpen(previousState),
        effect: (_action, { getState }) => {
            const isOpen = selectIsOpen(getState());

            toggleLoginTrigger(loginTriggerElements, isOpen);
        },
    });

    return loginTriggerElements;
};

/**
 * Update login trigger (logged in state)
 */
export const updateLoginTrigger = ({ navLink, indicator }: LoginTriggerElements, isLoggedIn: boolean): void => {
    if (!navLink) {
        return;
    }

    // Show loggedin state
    navLink.classList.toggle(DROPDOWN_OPENER_LOGGED_IN_CLASSNAME, isLoggedIn);

    const textResources = window.globalPageOptions?.myVf?.textResources;

    if (!isLoggedIn || !textResources) {
        // don't set aria-label if user is not logged in
        return;
    }

    indicator.setAttribute('aria-label', textResources.youAreLoggedIn);
};

/**
 * Toggle dropdown open class on trigger
 */
export const toggleLoginTrigger = ({ navLink }: LoginTriggerElements, isOpen: boolean): void => {
    if (!navLink) {
        return;
    }

    navLink.classList.toggle(DROPDOWN_OPENER_OPEN_CLASSNAME, isOpen);
    navLink.setAttribute('aria-expanded', isOpen.toString());
};

/**
 * Bind event listeners
 */
export const initLoginTriggerEvents = (
    { navItem, navLink }: LoginTriggerElements,
    relatedDropdown: HTMLElement,
) => {
    if (!navItem || !navLink || !relatedDropdown) {
        return;
    }

    const dispatch = useDispatch();

    navLink.addEventListener('click', e => {
        // prevent navLink click since the user is logged in
        e.preventDefault();

        // This click event handler handles three cases:
        //
        // 1. Clicking the link by touching the screen
        // 2. Clicking the link by using the mouse
        // 3. Clicking the link by pressing the enter key it is focussed
        //
        // Because in the desktop mode it should only be possible to open/close
        // the dropdown by mouseenter/mouseleave or using the keyboard but
        // not by clicking with the mouse following condition is used
        if (!isDesktopViewport() || isTouchInput() || isKeyboardTriggeredClickEvent(e)) {
            dispatch(toggleDropdownState());
        }
    });

    const handleNavLinkBlur = (e: FocusEvent) => {
        compareFocusPosition(e, relatedDropdown, Node.DOCUMENT_POSITION_PRECEDING, () => {
            // focus jumped from the navLink to a previous element so close the dropdown
            dispatch(toggleDropdownState(false));
        });
    };

    // handle blur on navlink
    navLink.addEventListener('blur', handleNavLinkBlur);

    addMouseEvents(navItem);
    addTouchEvents(navLink);
};

const addMouseEvents = (navItem: HTMLElement) => {
    const dispatch = useDispatch();

    type Timeouts = {
        [type in 'mouseenter' | 'mouseleave']: NodeJS.Timeout | null;
    };

    const timeouts: Timeouts = {
        mouseenter: null,
        mouseleave: null,
    };

    const handleNavItemMouseEnter = () => {
        if (isTouchInput()) {
            return;
        }

        if (timeouts.mouseleave) {
            // clear active closing timeout
            // (user entered, left and entered again within the closing timeout)
            clearTimeout(timeouts.mouseleave);
            timeouts.mouseleave = null;
        }
        else {
            // set opening timeout
            // (user entered the first time)
            timeouts.mouseenter = setTimeout(() => {
                timeouts.mouseenter = null;
                dispatch(toggleDropdownState(true));
            }, HOVER_DELAY);
        }
    };

    const handleNavItemMouseLeave = () => {
        if (isTouchInput()) {
            return;
        }

        if (timeouts.mouseenter) {
            // clear active opening timeout
            // (user entered and left again within the opening timeout)
            clearTimeout(timeouts.mouseenter);
            timeouts.mouseenter = null;
        }
        else {
            // set closing timeout
            // (user waited for opening and leaves)
            timeouts.mouseleave = setTimeout(() => {
                timeouts.mouseleave = null;
                dispatch(toggleDropdownState(false));
            }, HOVER_DELAY);
        }
    };

    const handleNavItemKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            // handle pressing escape when focus is on the nav item,
            // to close the dropdown
            dispatch(toggleDropdownState(false));
        }
    };

    // show dropdown on mouseenter for desktop users
    navItem.addEventListener('mouseenter', handleNavItemMouseEnter);

    // hide dropdown on mouseleave for desktop users
    navItem.addEventListener('mouseleave', handleNavItemMouseLeave);

    // hide dropdown when user presses Escape while focus is on navItem
    navItem.addEventListener('keydown', handleNavItemKeydown);
};

const addTouchEvents = (navLink: HTMLAnchorElement) => {
    const dispatch = useDispatch();

    const handleGlobalClick = (e: MouseEvent) => {
        const isLoginTriggerClick = navLink.contains(e.target as Node);

        if (isLoginTriggerClick) {
            // ignore clicks on the login trigger (navLink) itself
            return;
        }

        if (!navLink.classList.contains(DROPDOWN_OPENER_OPEN_CLASSNAME)) {
            // dropdown is closed already, so no need to dispatch the action
            return;
        }

        dispatch(toggleDropdownState(false));
    };

    document.addEventListener('click', handleGlobalClick);
};

export default mountLoginTrigger;
