import './style.scss';
import mountBasketFeature from './container/Basket';
import mountGlobalNavigationFeature from './container/GlobalNavigation';
import mountGlobalSearchFeature from './container/GlobalSearch';
import mountMeinVodafoneFeature from './container/MyVodafoneSection';
import mountContactFeature from './container/ContactSection';
import mountContentContainer from './container/Content';
import { initTouchDetection } from '@vfde-brix/core';

// init touch detection as early as possible
initTouchDetection();

mountBasketFeature();
mountGlobalNavigationFeature();
mountGlobalSearchFeature();
mountMeinVodafoneFeature();
mountContactFeature();
mountContentContainer();
