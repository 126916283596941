import {
    NAV_ANIMATION_IN,
    NAV_ANIMATION_OUT,
    NAV_ITEM_CLASSNAME,
    NAV_ITEM_LEVEL_3_CLASSNAME,
    NAV_LIST_CLASSNAME,
    NAV_ROOT_LEVEL_ID,
} from '../constants';
import { isDesktopViewport } from '@vfde-brix/core';
import { MddDirections } from '../interface';
import { closestUntil } from '../../../helpers/domHelper';

/**
 * desktopAnimationService
 * adds expand collapse animations to submenu in desktop (non burger) viewport
 */
export const initMddAnimations = (mdd: HTMLElement) => {
    const registerAnimations = () => {
        // Remove any animation classes added by previous viewports
        Array.from(mdd.getElementsByClassName(NAV_LIST_CLASSNAME))
            .forEach(list => list.classList.remove(NAV_ANIMATION_IN, NAV_ANIMATION_OUT));
        // Bind event handler for animations
        mdd.querySelectorAll<HTMLLIElement>(`.${NAV_ITEM_CLASSNAME}:not(.${NAV_ITEM_LEVEL_3_CLASSNAME})`).forEach((listItem: HTMLLIElement) => {
            ['nav:mouseenter', 'nav:mouseleave'].forEach(type => {
                listItem.removeEventListener(type as 'nav:mouseenter' | 'nav:mouseleave', handleNavMouseEvent);
                isDesktopViewport() && listItem.addEventListener(type as 'nav:mouseenter' | 'nav:mouseleave', handleNavMouseEvent);
            });
        });
    };

    registerAnimations();

    return () => {
        registerAnimations();
    };
};

/**
 * Event handler for navigation mouse events
 */
const handleNavMouseEvent = (e: CustomEvent) => {
    e.stopPropagation();

    const toElement = getToElement(e.detail.originalEvent);
    const fromElement = getFromElement(e.detail.originalEvent);

    const toList = toElement?.closest(`.${NAV_LIST_CLASSNAME}`);
    const fromList = fromElement?.closest(`.${NAV_LIST_CLASSNAME}`);

    const fromListChild = fromElement?.getElementsByClassName(NAV_LIST_CLASSNAME)[0];
    const toListChild = toElement?.getElementsByClassName(NAV_LIST_CLASSNAME)[0];

    const position = getRelatedPosition(toList!, fromList!);
    const direction = getDirection(fromList!, toList!, e.detail.originalEvent.type, position);

    const startAnimationIn = (element?: Element) => {
        if (!element) {
            return;
        }

        const handleAnimationEnd = () => {
            // remove NAV_ANIMATION_IN class as soon as animation has ended
            element.classList.remove(NAV_ANIMATION_IN);
            element.removeEventListener('animationend', handleAnimationEnd);
        };

        element.addEventListener('animationend', handleAnimationEnd);

        element.classList.remove(NAV_ANIMATION_OUT);
        element.classList.add(NAV_ANIMATION_IN);
    };

    const startAnimationOut = (element?: Element) => {
        if (!element) {
            return;
        }

        element.classList.remove(NAV_ANIMATION_IN);
        element.classList.add(NAV_ANIMATION_OUT);
    };

    switch (direction) {
        case MddDirections.In:
            startAnimationOut(toListChild);
            break;
        case MddDirections.Out:
            startAnimationIn(fromListChild);
            break;
        case MddDirections.Up:
            startAnimationIn(fromListChild);
            break;
        case MddDirections.Down:
            startAnimationOut(toListChild);
            break;
        case MddDirections.Horizontal:
            fromListChild?.classList.remove(NAV_ANIMATION_OUT);
            toListChild?.classList.remove(NAV_ANIMATION_IN);
            !toListChild && startAnimationIn(fromListChild);
            !fromListChild && startAnimationOut(toListChild);
            break;
    }
};

const getRelatedElement = (
    { currentTarget, relatedTarget, type }: MouseEvent,
    elementType: 'from' | 'to',
    matchNodeName = true,
): HTMLElement | null => {
    if ((elementType === 'to' && type.indexOf('enter') !== -1) || (elementType === 'from' && type.indexOf('leave') !== -1)) {
        return currentTarget as HTMLElement;
    }

    if (matchNodeName) {
        const element = relatedTarget as HTMLElement;
        const nodeName = (<HTMLElement>currentTarget).nodeName.toLowerCase();

        // Okay for now, because this helper is built for MDD. For more generic use cases we should hand the context over as parameter
        const context = document.getElementById(NAV_ROOT_LEVEL_ID);

        return closestUntil(element, nodeName, context!);
    }

    return relatedTarget as HTMLElement;
};

/**
 * Get the target element for mouse event
 * @param event Mouse Event
 * @param matchNodeName should match the same element as current target
 */
export const getToElement = (event: MouseEvent, matchNodeName = true): HTMLElement | null => getRelatedElement(event, 'to', matchNodeName);

/**
 * Get the source element for mouse event
 * @param event Mouse Event
 * @param matchNodeName should match the same element as current target
 */
export const getFromElement = (event: MouseEvent, matchNodeName = true): HTMLElement | null =>
    getRelatedElement(event, 'from', matchNodeName);

/**
 * Get position of target list compared to source list
 */
export const getRelatedPosition = (toList: Element, fromList: Element): number =>
    toList && fromList && toList.compareDocumentPosition(fromList);

/**
 * Get direction in which the user navigates through MDD
 */
export const getDirection = (
    fromList: Element | undefined,
    toList: Element | undefined,
    type: string, position: number,
): MddDirections | undefined => {
    if (!fromList) {
        return MddDirections.In;
    }

    if (!toList) {
        return MddDirections.Out;
    }

    if (toList === fromList) {
        return MddDirections.Horizontal;
    }

    if (type === 'mouseenter' && position === (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS)) {
        return MddDirections.Down;
    }

    if (type === 'mouseenter' && position === (Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY)) {
        return MddDirections.Up;
    }

    if (type === 'mouseleave' && position === (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS)) {
        return MddDirections.Down;
    }

    if (type === 'mouseleave' && position === (Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY)) {
        return MddDirections.Up;
    }
};
