import { META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME } from '../constants';
import { isKeyboardTriggeredClickEvent } from '@vfde-sails/utils';
import { useDispatch } from '../../../app/store';
import {
    selectContactNumber,
    toggleFlyout,
} from '../slice';
import { startAppListening } from '../../../app/listener';

/**
 * Render and mount contact number
 */
const mountContactNumber = (className: string): HTMLElement | undefined => {
    const containerElement = document.getElementsByClassName(className)[0] as HTMLElement;

    if (!containerElement) {
        return;
    }

    const dispatch = useDispatch();

    // click handler for mobile contact item and
    const contactClickHandler = (event: MouseEvent) => {
        event.preventDefault();
        dispatch(toggleFlyout(isKeyboardTriggeredClickEvent(event)));
    };

    containerElement.addEventListener('click', contactClickHandler);

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectContactNumber(currentState) !== selectContactNumber(previousState),
        effect: (_action, { getState }) => {
            const contactNumber = selectContactNumber(getState());

            if (contactNumber) {
                updateContactNumber(containerElement, contactNumber);
                containerElement.classList.toggle(META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME, !!contactNumber);
            }
        },
    });

    return containerElement;
};

/**
 * Update contact number
 */
export const updateContactNumber = (containerElement: HTMLElement, contactNumber: string) => {
    const renderContactNumber = require('./templates/number.pug');

    containerElement.innerHTML = renderContactNumber({
        numberToDisplay: contactNumber,
    });
};

export default mountContactNumber;
