import {
    Button,
    createButtonComponent,
    IButtonBusinessLogic,
    IButtonProperties,
} from '@vfde-brix/button';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';

/**
 * Mount button
 */
const mountButton = (selector: string, properties: IButtonProperties | IButtonBusinessLogic = NO_PATTERN_BUSINESS_LOGIC): Button | null => {
    const buttonContainer = document.querySelector<HTMLButtonElement>(selector);

    return buttonContainer && createButtonComponent(buttonContainer as Element, properties);
};

export default mountButton;
