import './style.scss';
import { OVERLAY_WRAPPER_ID } from './constants';
import mountSearchOverlay, { toggleOverlay } from './components/GlobalSearch';
import {
    renderSearchResults,
    resetSearchResults,
} from './components/SearchResultList';
import { startAppListening } from '../../app/listener';
import {
    selectIsOverlayOpen,
    selectQuery,
} from './slice';
import {
    selectHasError,
    selectSearchResults,
} from './selector';
import { trackRevealSearchOverlay } from './helpers/tracking';
import './listener';

function mountGlobalSearchFeature () {
    const elements = mountSearchOverlay(OVERLAY_WRAPPER_ID);

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectQuery(currentState) !== selectQuery(previousState),
        effect: (_action, { getState }) => {
            const query = selectQuery(getState());

            if (!query) {
                resetSearchResults(elements!);
            }
        },
    });

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectIsOverlayOpen(currentState) !== selectIsOverlayOpen(previousState),
        effect: (_action, { getState }) => {
            const isOverlayOpen = selectIsOverlayOpen(getState());

            isOverlayOpen && trackRevealSearchOverlay();

            toggleOverlay(elements!, isOverlayOpen);
        },
    });

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectHasError(currentState) !== selectHasError(previousState),
        effect: (_action, { getState }) => {
            const hasError = selectHasError(getState());

            elements?.errorNotification?.toggleContainer(!hasError);
        },
    });

    startAppListening({
        predicate: (_action, currentState, previousState) =>
            selectSearchResults(currentState) !== selectSearchResults(previousState),
        effect: (_action, { getState }) => {
            const results = selectSearchResults(getState());

            elements && elements.overlay && renderSearchResults(elements, results);
        },
    });
}

export default mountGlobalSearchFeature;
