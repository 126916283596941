import { NAV_CLASSNAME } from '../GlobalNavigation/constants';

/**
 * Class names
 */
export const NAV_ITEM_BASKET_CLASSNAME = `${NAV_CLASSNAME}__icon-nav-item--basket`;
export const BASKET_LINK_CLASSNAME = 'mc-nav-basket';
export const BASKET_INDICATOR_CLASSNAME = `${BASKET_LINK_CLASSNAME}__indicator`;
export const BASKET_INDICATOR_COUNT_CLASSNAME = `${BASKET_LINK_CLASSNAME}__indicator-count`;

/**
 * Miscellaneous
 */
export const BASKET_COUNT_DEFAULT_COOKIE = 'CARTCOUNT';
export const BASKET_COUNT_OLS_COOKIE = 'OLSCARTCOUNT';
