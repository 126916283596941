import { CLASSNAME_NO_SCROLL } from '@vfde-brix/core';
import {
    BREADCRUMB_HIDDEN_CLASSNAME,
    BREADCRUMB_LIST_CLASSNAME,
    BREADCRUMB_LIST_ITEM_LINK_CLASSNAME,
    BREADCRUMB_NEXT_CLASSNAME,
    BREADCRUMB_PREV_CLASSNAME,
    BREADCRUMB_WRAPPER_CLASSNAME,
} from '../constants';
import { isDesktopViewport } from '@vfde-brix/core';

/**
 * Adds the breadcrumb functionality
 * @param breadcrumb - The breadcrumb HTML element
 */
export const initBreadcrumb = (breadcrumb: HTMLElement): CallableFunction => {
    // CONST
    const NAV_MAX_POS = 0;
    const MIN_LARGE_VIEWPORT_WIDTH = 1280;
    const BREADCRUMB_VISIBLE_THRESHOLD = 10;

    // UI CONST
    const breadcrumbWrapper = breadcrumb.getElementsByClassName(BREADCRUMB_WRAPPER_CLASSNAME)[0] as HTMLElement;
    const breadcrumbList = breadcrumb.getElementsByClassName(BREADCRUMB_LIST_CLASSNAME)[0] as HTMLElement;
    const nextButton = breadcrumb.getElementsByClassName(BREADCRUMB_NEXT_CLASSNAME)[0] as HTMLButtonElement;
    const previousButton = breadcrumb.getElementsByClassName(BREADCRUMB_PREV_CLASSNAME)[0] as HTMLButtonElement;

    // WINDOW SCROLL HANDLER AUTO HIDE NAV BAR #######################################

    const handleWindowScrollEvent = () => {
        let lastScrollY = window.scrollY;
        let pageScrollWasDisabled = false;

        window.addEventListener('scroll', () => {
            const pageScrollIsDisabled = document.body.classList.contains(CLASSNAME_NO_SCROLL);

            if (pageScrollIsDisabled) {
                // Page scrolling is currently disabled
                pageScrollWasDisabled = true;

                return;
            }

            if (pageScrollWasDisabled) {
                // Page scrolling is currently enabled but was disabled in the previous scroll event.
                // This means that the current scroll event was triggered by the helper function `enablePageScroll`
                // which restores the last scroll position and this triggered the current scroll event.
                // Without this condition the breadcrumb would be be shown after the mobile navi is closed using the burger button.
                pageScrollWasDisabled = false;

                return;
            }

            const newScrollY = window.scrollY;
            const hideBreadcrumb = newScrollY > lastScrollY && newScrollY > BREADCRUMB_VISIBLE_THRESHOLD;

            // hideBreadcrumb is true when the user is scrolling down
            // and the scroll position is not within the top threshold
            // where the breadcrumb should always be visible

            breadcrumb.classList.toggle(BREADCRUMB_HIDDEN_CLASSNAME, hideBreadcrumb);

            lastScrollY = newScrollY;
        }, { passive: true });
    };

    handleWindowScrollEvent();

    // BUTTON HANDLERS #######################################

    // TODO: remove events on mobile
    previousButton.addEventListener('click', () => {
        setPositionEnd(false);
    });

    nextButton.addEventListener('click', () => {
        setPositionEnd(true);
    });

    // ITEM FOCUS HANDLERS #######################################

    const addFocusHandler = () => {
        breadcrumb.addEventListener('focusin', e => {
            const link = e.target as HTMLElement;

            if (!link.classList.contains(BREADCRUMB_LIST_ITEM_LINK_CLASSNAME)) {
                // filter out events which weren't triggered by a link
                return;
            }

            breadcrumb.classList.remove(BREADCRUMB_HIDDEN_CLASSNAME);

            if (breadcrumbList.clientWidth < getAvailableWidth()) {
                return;
            }

            const offsetLeft = getOffset(link).left;
            const navMinPos = -(breadcrumbList.clientWidth - breadcrumbWrapper.clientWidth);
            let targetXPos = -offsetLeft + 12;

            // todo set gradient

            if (!isDesktopViewport()) {
                targetXPos = Math.max(targetXPos, navMinPos);
                setBreadcrumbX(targetXPos);

                return;
            }

            if (offsetLeft < (breadcrumbList.clientWidth + link.clientWidth) / 2) {
                setPositionEnd();
            }
            else {
                setPositionEnd(true);
            }
        });
    };

    // DRAG BAR HANDLERS #######################################

    let savedBreadcrumbPosition: number;
    let startTouchPos: number;

    breadcrumbList.addEventListener('touchstart', event => {
        if (breadcrumbList.clientWidth < getAvailableWidth()) {
            return;
        }

        startTouchPos = getMousePosition(event);
        savedBreadcrumbPosition = getBreadcrumbX();
    });

    breadcrumbList.addEventListener('touchmove', event => {
        if (breadcrumbList.clientWidth < getAvailableWidth()) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        const navMinPosition = -(breadcrumbList.clientWidth - breadcrumbWrapper.clientWidth);
        let targetPos = getMousePosition(event) - startTouchPos - savedBreadcrumbPosition;

        if (targetPos > NAV_MAX_POS) {
            targetPos = NAV_MAX_POS;
        }
        else if (targetPos < navMinPosition) {
            targetPos = navMinPosition;
        }

        setBreadcrumbX(targetPos);

        // also make the gradients visible
        if (targetPos === 0) {
            previousButton.style.display = 'none';
            nextButton.style.display = 'flex';
        }
        else if (targetPos === navMinPosition) {
            previousButton.style.display = 'flex';
            nextButton.style.display = 'none';
        }
        else {
            previousButton.style.display = 'flex';
            nextButton.style.display = 'flex';
        }
    });

    // HELPER FUNCTIONS #######################################

    /**
     * initBreadcrumbListPosition -- is called on page load and on resize
     */
    const initBreadcrumbListPosition = () => {
        if (breadcrumbList.clientWidth > getAvailableWidth()) {
            setPositionEnd(true);
        }
        else {
            setPositionEnd(false);
            previousButton.style.display = 'none';
            nextButton.style.display = 'none';
        }
    };

    /**
     * setPositionEnd - set the position of the breadcrumbList either end or to start
     * @param end - set position to end (false) or to start (true)
     */
    const setPositionEnd = (end = false) => {
        let breadcrumbListPos = 0;
        previousButton.style.display = 'none';
        nextButton.style.display = 'flex';

        if (end) {
            breadcrumbListPos = -(breadcrumbList.clientWidth - getAvailableWidth());
            previousButton.style.display = 'flex';
            nextButton.style.display = 'none';
        }

        setBreadcrumbX(breadcrumbListPos);
    };

    /**
     * getAvailableWidth
     * @returns the width for the breadcrumb - either the document body width or 1280
     */
    const getAvailableWidth = () => Math.min(breadcrumbWrapper.clientWidth, MIN_LARGE_VIEWPORT_WIDTH);

    /**
     * getMousePosition
     * @param event - mouse event
     * @returns the mouse / touch position
     */
    const getMousePosition = (event: any) => event.touches[0].clientX;

    /**
     * getOffset
     * @param el - HTML element which the offset should be calculated on
     * @returns an offset object with top and left props
     */
    const getOffset = (el: any) => {
        let x = 0;
        let y = 0;

        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            x += el.offsetLeft - el.scrollLeft;
            y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }

        return { top: y, left: x };
    };

    /**
     * get the x-pos as number
     */
    const getBreadcrumbX = () => {
        const breadcrumbTransformValue = breadcrumbList.style.transform.replace(/[^\d.]/g, '');

        return parseInt(breadcrumbTransformValue, 10);
    };

    /**
     * set the x-pos as number
     */
    const setBreadcrumbX = (targetXPos: number) => {
        breadcrumbList.style.transform = `translateX(${targetXPos}px)`;
    };

    initBreadcrumbListPosition();
    addFocusHandler();

    // RESIZE HANDLER #######################################

    return () => {
        initBreadcrumbListPosition();
    };
};
